import React from 'react';

const TOS = () => {
  return (
    <div className="tos-div">
      <h1>Terms of Service</h1>
      <p>Last Updated: June 8, 2021</p>

      <h3>Introduction</h3>
      <p>
        Welcome to Ponder. Please carefully read on to learn the rules and
        restrictions that govern your use of our website(s), products, services
        and applications (“Ponder”).
      </p>
      <p>
        These Terms of Service (the “Terms,” “Agreement”) are a binding contract
        between you and Ponder. (“Ponder,” “we” and “us”). By accessing or using
        Ponder you agree to be bound by these Terms. Your access to and use of
        Ponder are conditioned upon your acceptance of and compliance with these
        Terms. These Terms apply to all visitors, users and others who wish to
        access or use the Services.
      </p>
      <h3>Modification</h3>
      <p>
        Ponder reserves the right, at its sole discretion, to modify these Terms
        at any time and without prior notice. If we modify these Terms, we will
        either post a notification of the modification on our website or
        otherwise provide you with notice of the change. The date of the last
        modification will also be posted at the beginning of these Terms. It is
        your responsibility to check from time to time for updates. By
        continuing to access or use Ponder, you accept and agree to be bound by
        any modified Terms.
      </p>
      <h3>Privacy Policy</h3>
      <p>
        Our <a href="/privacy">Privacy Policy</a> discusses how we collect,
        process, and safeguard your personal information through use of our
        Application. Please read the Privacy Policy carefully prior to using our
        Application.
      </p>
      <h3>User Eligibility</h3>
      <p>
        If you use Ponder on behalf of a company, partnership, association, or
        other entity, you hereby represent and warrant that you have the
        capacity to enter into these Terms on behalf of the entity, or that an
        authorized representative of the entity has agreed to bind the entity to
        these Terms.
      </p>
      <p>
        If you are younger than 13, you may not download or use Ponder Services
        or access or provide any content. If you are age 13 or older but under
        the age of 18, you may use Ponder Services only under the supervision of
        a parent or legal guardian who agrees to be bound by this Policy. If you
        are a parent or legal guardian agreeing to the Terms for the benefit of
        a child who is 13 or older but under the age of 18, you are fully
        responsible for your child’s use of the Services.
      </p>
      <h3>Acceptable Use</h3>
      <p>
        Ponder hereby grants you permission to use the application provided such
        use is in compliance with these Terms.
      </p>
      <p>
        You further specifically agree that your use will adhere to the
        following restrictions and obligations where you agree not to use
        Ponder:
        <ul>
          <li>
            In any way that violates any applicable federal, state, local, or
            international law or regulation.
          </li>
          <li>
            To send, knowingly receive, upload, download, use, or re-use any
            material that does not comply with the the Terms of Service, Privacy
            Policy, or any other user policies of Ponder.
          </li>
          <li>
            To transmit, or procure the sending of, any advertising or
            promotional material, including any "junk mail," "chain letter,"
            "spam," or any other similar solicitation.
          </li>
          <li>
            To impersonate or attempt to impersonate Ponder, a Ponder employee,
            another user, or any other person or entity (including, without
            limitation, by using email addresses or account names associated
            with any of the foregoing).
          </li>
          <li>
            To engage in any other conduct that restricts or inhibits anyone's
            use or enjoyment of Ponder, or may harm the owners and operators of
            Ponder.
          </li>
          <li>
            In any manner that could disable, overburden, damage, or impair the
            site or interfere with any other party's use of Ponder.
          </li>
          <li>
            To use any robot, spider, or other automatic device, process, or
            means to access Ponder for any purpose, including monitoring or
            copying any of the material on Ponder.
          </li>
        </ul>
      </p>
      <p>
        Additionally, you may be required to sign up for an account and select a
        password and username (User ID). You may not select as your User ID the
        name of a person or entity you don’t have the right to use, or another
        person or entity's name with the intent to impersonate that person or
        entity. Only you may use your account and you may not transfer your
        account to anyone else.
      </p>
      <h3>User Generated Content</h3>
      <p>
        We may, at our sole discretion, permit you to post, upload, publish,
        submit, or transmit content including but not limited to journal entries
        (“User Content”). You acknowledge and agree that you are solely
        responsible for all User Content. Accordingly, you represent and warrant
        that you have all rights, licenses, consents and releases that are
        necessary to generate and upload such content.
      </p>
      <h3>Termination and Monitoring</h3>
      <p>
        Ponder has the right to:
        <li>Remove any User Accounts for any reason in our sole discretion.</li>
        <li>
          Remove or refuse to post any User Contributions for any or no reason
          in our sole discretion.
        </li>
        <li>
          Take any action with respect to any User Contribution that we deem
          necessary or appropriate in our sole discretion, including if we
          believe that such User Contribution violates the Terms of Service,
          including our Content Standards, infringes any intellectual property
          other right of any person or entity, threatens the personal safety of
          users of the Website or the public, or could create liability for
          Ponder.
        </li>
        <li>
          Take appropriate legal action, including without limitation, referral
          to law enforcement, for any illegal or unauthorized use of Ponder.
        </li>
        <li>
          Terminate or suspend your access to all or part of the Ponder for any
          reason, including violations of these Terms, violation of any
          applicable laws, or to protect the safety or property of other users,
          Ponder, or third parties.
        </li>
      </p>
      <p>
        Upon receipt of your request to delete your account, and except as set
        forth below, we may remove your account and associated information
        within a reasonable time period. Please note that any information you
        have submitted to publicly accessible areas may not be removeable.
      </p>
      <h3>Warranties</h3>
      <p>
        YOU HEREBY ACKNOWLEDGE THAT YOU ARE USING PONDER AT YOUR OWN RISK. THE
        SERVICES AND CONTENT ARE PROVIDED "AS IS," AND THE PONDER SERVICES, ITS
        AFFILIATES, AND ITS THIRD PARTY SERVICE PROVIDERS HEREBY DISCLAIM ANY
        AND ALL WARRANTIES, EXPRESS AND IMPLIED, INCLUDING BUT NOT LIMITED TO
        ANY WARRANTIES OF ACCURACY, RELIABILITY, MERCHANTABILITY,
        NON-INFRINGEMENT, FITNESS FOR A PARTICULAR PURPOSE, AND ANY OTHER
        WARRANTY, CONDITION, GUARANTEE OR REPRESENTATION, EXPRESS OR IMPLIED,
        WHETHER ORAL, IN WRITING OR IN ELECTRONIC FORM. PONDER, ITS AFFILIATES,
        AND ITS THIRD PARTY SERVICE PROVIDERS DO NOT REPRESENT OR WARRANT THAT
        ACCESS TO THE SERVICES WILL BE UNINTERRUPTED OR THAT THERE WILL BE NO
        FAILURES, ERRORS OR OMISSIONS OR LOSS OF TRANSMITTED INFORMATION, OR
        THAT NO VIRUSES OR OTHER MALWARE WILL BE TRANSMITTED THROUGH THE
        SERVICES.
      </p>
      <h3>Limitation of Liability</h3>
      <p>
        TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COLLECTIVE
        LIABILITY OF THE COMPANY AND ITS SUBSIDIARIES AND AFFILIATES, AND THEIR
        LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND
        DIRECTORS, TO ANY PARTY (REGARDLESS OF THE FORM OF ACTION, WHETHER IN
        CONTRACT, TORT, OR OTHERWISE) EXCEED $20 OR THE AMOUNT YOU HAVE PAID TO
        THE COMPANY FOR THE APPLICABLE CONTENT, PRODUCT, OR SERVICE OUT OF WHICH
        LIABILITY AROSE.
      </p>
      <h3>Notices</h3>
      <p>
        Any notices or other communications permitted or required hereunder,
        including those regarding modifications to these Terms, will be in
        writing and given by Ponder by posting to the website.
      </p>
      <h3>No Waiver</h3>
      <p>
        No waiver by Ponder of any term or condition set out in these Terms of
        Service shall be deemed a further or continuing waiver of such term or
        condition or a waiver of any other term or condition, and any failure of
        the Ponder to assert a right or provision under these Terms of Service
        shall not constitute a waiver of such right or provision.
      </p>
      <h3>Assignment</h3>
      <p>
        You may not assign or transfer these Terms, by operation of law or
        otherwise, without Ponder’s prior written consent. Any attempt by you to
        assign or transfer these Terms without such consent will be null and of
        no effect. Ponder may assign or transfer these Terms, at its sole
        discretion, without restriction. Subject to the foregoing, these Terms
        will bind and inure to the benefit of the parties, their successors and
        permitted assigns. These Terms do not and are not intended to confer any
        rights or remedies upon any person other than the parties.
      </p>
      <h3>Severability</h3>
      <p>
        If any provision of this Agreement is held illegal or unenforceable in a
        judicial proceeding, such provision shall be severed and shall be
        inoperative, and the remainder of these Terms shall remain operative and
        binding on the user. Furthermore, Ponder 's failure to enforce any right
        or provision in these Terms shall not constitute a waiver of such right
        or provision unless acknowledged and agreed to by Ponder in writing.
      </p>
      <h3>Entire Agreement</h3>
      <p>
        These Terms constitute the entire agreement between you and Ponder
        regarding your use of the Ponder Services and supersede all prior
        written or oral agreements.
      </p>
      <h3>Contact Us</h3>
      <p>
        If you have any questions about Ponder, please do not hesitate to
        contact us at <a href="mailto:help@getponder.app">help@getponder.app</a>
        .
      </p>
    </div>
  );
};

export default TOS;
