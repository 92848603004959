import { Card, Container } from '@material-ui/core';
import React from 'react';

type Props = {
  children: React.ReactNode;
};
const LegalContainer = (props: Props) => {
  return (
    <>
      <img className="background" alt="background" src="/wallpaper.jpg" />
      <Container>
        <Card className="my-5 p-5">
          <h1 className="ponder-font">Ponder</h1>
          {props.children}
        </Card>
      </Container>
    </>
  );
};

export default LegalContainer;
