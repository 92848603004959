import React, { useContext, useEffect, useState } from 'react';
import { UserMessage } from '../shared/types';
import ServerHelper, { ServerURL } from './ServerHelper';
import { initializeApp } from 'firebase/app';
import {
  UserCredential,
  GoogleAuthProvider,
  EmailAuthProvider,
} from 'firebase/auth';

interface IUserContext {
  user: UserMessage | null;
  updateUser: () => Promise<void>;
  uiConfig: firebaseui.auth.Config;
}

const firebaseConfig = {
  apiKey: 'AIzaSyCcouNylKbg1mG4Ch-Ti9bEc1-a8_ZWt24',
  authDomain: 'getponderapp.firebaseapp.com',
  projectId: 'getponderapp',
  storageBucket: 'getponderapp.appspot.com',
  messagingSenderId: '940375900954',
  appId: '1:940375900954:web:ed006d518aced4e7d622a3',
  measurementId: 'G-SSGWF0Q4SL',
};

initializeApp(firebaseConfig);

export const UserContext = React.createContext<IUserContext>(null!);
export const useUser: () => IUserContext = () =>
  useContext<IUserContext>(UserContext);

type UserProviderProps = {
  children: React.ReactNode;
};
export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<UserMessage | null>(null);

  const updateUser = React.useCallback(async () => {
    const json = await ServerHelper.post(ServerURL.GET_SESSION, {});
    if (!json.success) {
      return;
    }
    const user: UserMessage = json.user;
    setUser(user);
    return;
  }, [setUser]);

  useEffect(() => {
    updateUser();
  }, [updateUser]);

  const uiConfig: firebaseui.auth.Config = {
    callbacks: {
      signInSuccessWithAuthResult: (result: UserCredential) => {
        (async () => {
          const token = await result.user?.getIdToken();
          if (!result.user) {
            alert('Could not login');
            return;
          }
          const user: UserMessage = {
            name: result.user.displayName || '',
            email: result.user.email || '',
          };
          console.log('Login Success', user, token);
          setUser(user);
          const json = await ServerHelper.post(ServerURL.AUTH, {
            idToken: token,
          });
          if (!json.success) {
            alert('Could not login');
          }
        })();
        return false; // We will reload anyway
      },
      uiShown: function () {
        // The widget is rendered.
        // Hide the loader.
        const loader = document.getElementById('loader');
        if (loader != null) {
          loader.style.display = 'none';
        }
      },
    },
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
      GoogleAuthProvider.PROVIDER_ID,
      EmailAuthProvider.PROVIDER_ID,
    ],
    tosUrl: '/tos',
    privacyPolicyUrl: '/privacy',
  };

  return (
    <UserContext.Provider
      value={{
        user,
        updateUser,
        uiConfig,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
