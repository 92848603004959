export enum ServerURL {
  GET_SESSION = '/api/v1/session',
  AUTH = '/auth/google',
}

const ServerHelper = {
  post: async (
    url: ServerURL,
    data: any,
  ): Promise<{ success: boolean; [key: string]: any }> => {
    try {
      const config = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...data, typeVersion: 1 }),
      };
      const response = await fetch(url, config);
      if (response.ok) {
        const json = await response.json();
        json.success = true;
        return json;
      } else {
        return { success: false, error_code: response.status };
      }
    } catch (error) {}
    return { success: false, error_code: -1 };
  },
  get: async (
    url: ServerURL,
  ): Promise<{ success: boolean; [key: string]: any }> => {
    try {
      const config = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };
      const response = await fetch(url, config);
      if (response.ok) {
        const json = await response.json();
        json.success = true;
        return json;
      } else {
        return { success: false, error_code: response.status };
      }
    } catch (error) {}
    return { success: false, error_code: -1 };
  },
};

export default ServerHelper;
