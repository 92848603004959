import { useCookies } from 'react-cookie';
import ServerHelper, { ServerURL } from './../components/ServerHelper';
import useData, { LSKey } from './useData';

const useLogin = () => {
  const data = useData();
  const [cookies, setCookie, removeCookie] = useCookies(['token', 'email']);

  const redirectToDopeAuth = (data?: [string, string][]) => {
    let otherdata = '';
    if (data != null && data.length > 0) {
      data.forEach((value) => {
        otherdata += `${value[0]}=${value[1]}&`;
      });
      if (otherdata.length > 0) {
        otherdata = `?${otherdata.substring(0, otherdata.length - 1)}`;
      }
    } else {
      if (!window.location.pathname.startsWith('/landing')) {
        otherdata += `?redirect=${encodeURIComponent(
          window.location.pathname + window.location.search,
        )}`;
      }
    }
    window.location.href =
      'https://dopeauth.com/login/' +
      encodeURIComponent(
        (process.env.REACT_APP_SITEURL || window.location.origin) + '/auth',
      ) +
      otherdata;
  };

  const logout = () => {
    removeCookie('token', { path: '/', domain: process.env.REACT_APP_HOST });
    removeCookie('email', { path: '/', domain: process.env.REACT_APP_HOST });
    data.setLocalStorageOrRemove(
      LSKey.VERSION,
      process.env.REACT_APP_MIN_VERSION,
    );
    data.setLocalStorageOrRemove(LSKey.ORG_DATA);
  };

  const version = data.getLocalStorageOrNULL(LSKey.VERSION);
  if (process.env.REACT_APP_MIN_VERSION !== version) {
    logout();
  }

  const callAPI = async (url: ServerURL, data: any) => {
    const res = await ServerHelper.post(url, data);
    if (!res.success && res.error_code === 999) {
      // Bad token
      logout();
    }
    return res;
  };

  return { redirectToDopeAuth, logout, callAPI };
};

export default useLogin;
