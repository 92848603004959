import React, { useEffect } from 'react';
import './App.scss';
import { CookiesProvider } from 'react-cookie';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import { SnackbarProvider } from 'notistack';
import { UserProvider } from './components/UserProvider';
import HomePage from './components/HomePage';
import ReactGA from 'react-ga';
import Privacy from './components/Legal/Privacy';
import LegalContainer from './components/Legal/LegalContainer';
import TOS from './components/Legal/TOS';
import UserPage from './components/UserPage';

// Initialize google analytics page view tracking
export const logPage = (): void => {
  ReactGA.set({ page: window.location.pathname }); // Update the user's current page
  ReactGA.pageview(window.location.pathname); // Record a pageview for the given page
};

const ClosePage = () => {
  useEffect(() => {
    window.close();
  }, []);
  return <Redirect to="/" />;
};

const App: React.FC = () => {
  document.title = process.env.REACT_APP_NAME || '';

  useEffect(() => {
    console.log('v' + (process.env.REACT_APP_VERSION || ''));
    logPage();
  }, []);

  return (
    <Router>
      <SnackbarProvider>
        <CookiesProvider>
          <UserProvider>
            <div className="App">
              <Switch>
                <Route path="/user" component={UserPage} />
                <Route
                  path="/privacy"
                  render={() => <LegalContainer children={<Privacy />} />}
                />
                <Route
                  path="/tos"
                  render={() => <LegalContainer children={<TOS />} />}
                />
                <Route path="/success" component={ClosePage} />
                <Route path="/" component={HomePage} />
              </Switch>
            </div>
          </UserProvider>
        </CookiesProvider>
      </SnackbarProvider>
    </Router>
  );
};

export default App;
