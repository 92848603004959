import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Container,
  Fade,
} from '@material-ui/core';
import { isChrome } from 'react-device-detect';
import useLogin from '../hooks/useLogin';
import useViewer, { Query } from '../hooks/useViewer';
import ServerHelper, { ServerURL } from './ServerHelper';
import { useUser } from './UserProvider';
import { StyledFirebaseAuth } from 'react-firebaseui';
import { getAuth } from 'firebase/auth';

const UserPage = () => {
  const { viewer, isLoggedIn } = useViewer();
  const loginhook = useLogin();
  const { uiConfig, user } = useUser();

  const [content, setContent] = useState<JSX.Element | null>(null);
  useEffect(() => {
    if (!isLoggedIn) {
      setContent(() => (
        <>
          <h1 className="ponder-font centered">Ponder</h1>
          <p className="centered">You are not logged in</p>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={getAuth()} />
        </>
      ));
    }
  }, [uiConfig, isLoggedIn]);
  useEffect(() => {
    if (user) {
      setContent(
        <>
          <h1 className="ponder-font centered" style={{ cursor: 'default' }}>
            Ponder
          </h1>
          <p>
            You are signed in as <i>{user.email}</i>
          </p>

          <div className="centered">
            <ButtonGroup>
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  loginhook.logout();
                  // This will delete the cookie
                  ServerHelper.post(ServerURL.GET_SESSION, {});
                }}
              >
                Signout
              </Button>
              {isChrome ? (
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => {
                    window.close();
                  }}
                >
                  Close Window
                </Button>
              ) : null}
            </ButtonGroup>
            {isChrome ? null : 'You can close this window'}
          </div>
        </>,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return (
    <div className="one-page">
      <Fade in={true} timeout={1200}>
        <img className="background" alt="background" src="/wallpaper.jpg" />
      </Fade>
      <Container className="py-5 full centered">
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          className="full"
        >
          <AnimatePresence>
            <motion.div
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{ duration: 1 }}
            >
              <Card className="p-5 ponder-card">
                <div className="card-content">{content}</div>
              </Card>
            </motion.div>
          </AnimatePresence>
        </Box>
      </Container>
    </div>
  );
};

export default UserPage;
